













import { defineComponent, reactive, toRefs } from '@vue/composition-api';
import Vue from 'vue';

export default defineComponent({
  name: 'RefreshButton',
  props: {
    refreshFunction: {
      type: Function,
      default: () => {
        Vue.$log.info('No refresh function is set');
      }
    },
    outlined: {
      default: false,
      type: Boolean
    },
    classList: {
      default: () => [],
      type: Array
    },
    color: {
      default: 'primary',
      type: String
    },
    dense: {
      default: true,
      type: Boolean
    }
  },
  setup(props, context) {
    // The function passed into props must be anonymous, or wrapped in an anonymous function
    // Ex: Doesn't work: getItems()
    // Ex: Does work: () => getItems()
    // Ex: Does work:    <refresh-button :refreshFunction="refreshItems"></refresh-button>
    const clickFunc = props?.refreshFunction
      ? props.refreshFunction
      : () => Vue.$log.debug('No function in slot yet');

    return {
      clickFunc
    };
  }
});
