var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      class: _vm.classList,
      attrs: {
        outlined: _vm.outlined,
        color: _vm.color,
        plain: "",
        icon: "",
        title: "Refresh the page"
      },
      on: { click: _vm.clickFunc }
    },
    [
      _c("v-icon", { staticClass: "mr-1" }, [_vm._v("mdi-refresh")]),
      _vm._v(_vm._s(_vm.dense ? undefined : "Refresh"))
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }