

























import Vue from 'vue';
import '@/lib/log';

import { defineComponent, reactive, toRefs } from '@vue/composition-api';

interface ConfirmationOptions {
  color?: string;
  width?: number;
  buttonOk?: string;
  buttonCancel?: string;
  zIndex?: number;
}
interface ConfirmationConfig {
  title?: string;
  message?: string;
  options?: ConfirmationOptions;
}

const state = reactive({
  dialog: false,
  resolve: (val: boolean) => {}, // eslint-disable-line
  reject: (val: boolean) => {}, // eslint-disable-line
  message: 'Are you sure you want to continue?',
  title: 'Confirmation'
});
const options = reactive({
  color: 'primary',
  width: 400,
  zIndex: 200,
  buttonOk: 'Yes',
  buttonCancel: 'Cancel'
});

const resetState = () => {
  state.message = 'Are you sure you want to continue?';
  state.title = 'Confirmation';
  state.dialog = false;
};

export function useConfirmation() {
  const ok = () => {
    Vue.$log.debug('Dialog accepted');
    state.resolve(true);
    resetState();
  };

  const cancel = () => {
    Vue.$log.debug('Dialog cancelled.');
    state.resolve(false);
    resetState();
  };

  const open = (config: ConfirmationConfig = {}) => {
    state.dialog = true;
    if (config) {
      if (config.title) {
        state.title = config.title;
      }
      if (config.message) {
        state.message = config.message;
      }
      if (config.options && Object.keys(config.options).length > 0) {
        Object.assign(options, config.options);
      }
    }

    return new Promise<boolean>((resolve, reject) => {
      state.resolve = resolve;
      state.reject = reject;
    });
  };

  return { ok, cancel, ...toRefs(state), ...toRefs(options), open };
}

export default defineComponent({
  setup() {
    const {
      message,
      title,
      width,
      color,
      zIndex,
      buttonOk,
      buttonCancel,
      dialog,
      ok,
      cancel
    } = useConfirmation();
    return { message, title, width, color, zIndex, buttonOk, buttonCancel, dialog, ok, cancel };
  }
});
