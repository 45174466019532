var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            "retain-focus": false,
            "max-width": _vm.width,
            width: _vm.width
          },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            {},
            [
              _c(
                "v-card-title",
                { staticClass: "headline", attrs: { color: _vm.color } },
                [_vm._v(" " + _vm._s(_vm.title) + " ")]
              ),
              _c("v-card-text", [_vm._v(" " + _vm._s(_vm.message) + " ")]),
              _c(
                "v-card-actions",
                { staticClass: "pb-4" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { outlined: "", color: "error" },
                      on: {
                        click: function($event) {
                          return _vm.cancel()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.buttonCancel) + " ")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { outlined: "", color: "success darken-1" },
                      on: {
                        click: function($event) {
                          return _vm.ok()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.buttonOk) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }